import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/apple-store.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/feature_1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/feature_2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/feature_3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/golf_groups.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/google-play.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/landing-1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/public/ratings.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
